import React from 'react';
import { Navigation } from './navigation'; // Make sure the path is correct

export const FAQ = () => {
  // Hardcoded FAQ data
  const faqs = [
    {
      question: "Q: How much does solar cost?",
      answer: "Solar energy costs less than traditional electricity from your utility company. We offer a variety of options including $0 down to purchase your system or pay as you go."
    },
    {
      question: "Q: If I wait five years, won’t the systems produce more and cost less?",
      answer: "The costs are artificially low due to incentives that will expire by the end of 2016. While the efficiency and related size of PV panels will increase marginally, the reliability and cost of premium products will not change enough to offset existing incentives."
    },
    {
      question: "Q: What are the typical benefits when investing in solar?",
      answer: "There are many benefits to investing in solar. A few are: Prices for residential and commercial solar equipment are the lowest in years, Money-saving incentives are available, Tax Credits, Rebates from Power Companies."
    },
    {
      question: "Q: What does GotSolar do?",
      answer: "GotSolar makes it easy and affordable by offering multiple financing and lending options. We simplify the entire process for purchasing solar energy by offering professional customized quotes, financing, scheduling, permitting, installation and certification."
    },
    {
      question: "Q: How will solar panels look on my house?",
      answer: "At GotSolar, we offer the best industry's American-made products that will look great on any house. Our black-on-black, American-made panels with our low-profile mounting hardware are extremely sleek. So sleek, you’ll be catching your neighbors staring at your roof from afar."
    },
    {
      question: "Q: How much money will I really save?",
      answer: "We understand, money is right up there with oxygen. How much you actually save is different for everyone. We take the time to design and install a system that matches your usage. "
    },
    {
      question: "Q: What happens if I move?",
      answer: "We get this question a lot! We understand that your current home may not be your forever home. The system usually stays with the home and even helps sell your home a lot faster."
    },
    {
      question: "Q: Still have more questions?",
      answer: "Call or email us. We would love to help answer any questions you have and schedule a consultation to review your options."
    },
    // Add more questions and answers as needed
  ];

  return (
    <div id='faq' className='faq-section'>
      <Navigation />
      <div className='container'>
        <div className='section-title'>
          <h2>Frequently Asked Questions</h2>
          <hr />
        </div>
        <div className='faq-list'>
          {faqs.map((faq, index) => (
            <div key={index} className='faq-item'>
              <p className='faq-question'>{faq.question}</p>
              <p className='faq-answer'><strong>{faq.answer}</strong></p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
