import React from "react";
import { useForm, ValidationError } from '@formspree/react';

export const Contact = (props) => {
  const [state, handleSubmit] = useForm("mqkrbejz"); 

  if (state.succeeded) {
    return <div id="contact">
      <div className="container text-center">
      <p>Thanks for your message! We will get back to you soon.</p>
      </div>
    </div>;
  }

  return (
    <div>
      <div id="contact">
        <div className="container text-center">
          <div className="contact-info">
            <h2>Contact Us</h2>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  placeholder="Your Name"
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  placeholder="Your Email"
                  required
                />
                <ValidationError prefix="Email" field="email" errors={state.errors} />
              </div>
              <div className="form-group">
                <input
                  type="tel"
                  name="phoneNumber"
                  className="form-control"
                  placeholder="Your Phone Number"
                />
              </div>
              <div className="form-group">
                <textarea
                  name="message"
                  className="form-control"
                  placeholder="Your Message"
                  required
                />
                <ValidationError prefix="Message" field="message" errors={state.errors} />
              </div>
              <button type="submit" className="btn btn-primary" disabled={state.submitting}>Send Message</button>
            </form>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>&copy; 2024</p>
        </div>
      </div>
    </div>
  );
};
