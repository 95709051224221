import React from "react";

export const Image = ({ title, link, smallImage }) => {
  const imageStyle = {
    height: "500px", // Set the desired height for all images
    width: "100%"
  };

  return (
    <div className="portfolio-item">
      <div className="hover-bg">
        {" "}
        <div>
          <div className="hover-text">
            <a href={link}>{title}</a>
          </div>
          <img
            src={smallImage}
            className="img-responsive"
            alt={title}
            style={imageStyle} // Apply the imageStyle
          />{" "}
        </div>{" "}
      </div>
    </div>
  );
};
